import { createFileRoute } from '@tanstack/react-router';
import { useNavigate } from '@tanstack/react-router';
import { toast } from 'sonner';

import { useTranslation } from '@adframe/translation';
import {
	InputOTP,
	InputOTPGroup,
	InputOTPSeparator,
	InputOTPSlot,
} from '@adframe/ui/components/otp';
import useAuth from '#app/hooks/use-auth.js';

import { z } from 'zod';
import logoDark from '#app/assets/adframe-logo-dark-mode.png';
import logoLight from '#app/assets/adframe-logo-light-mode.png';
import { useTheme } from '#app/providers/theme.tsx';

const validateSearch = z.object({
	email: z.string().email(),
});

export const Route = createFileRoute('/sign/confirm')({
	component: RouteComponent,
	onError: (router) => {
		router.navigate({ to: '/sign' });
	},
	validateSearch,
	staticData: {
		guest: true,
	},
});

function RouteComponent() {
	const { signConfirmCode } = useAuth();
	const { email } = Route.useSearch();
	const navigate = useNavigate({ from: '/sign/confirm' });
	const { t } = useTranslation();
	const { theme } = useTheme();

	const onComplete = async (code: string) => {
		try {
			await signConfirmCode({ code, email });
			navigate({ to: '/' });
		} catch (error) {
			toast.error('Something went wrong', { position: 'top-center' });
			navigate({ to: '/sign' });
		}
	};

	return (
		<>
			<div className='flex flex-col gap-6 sm:mx-auto sm:w-full sm:max-w-md 2xl:max-w-lg'>
				<div>
					<img
						className='mx-auto h-10 w-auto'
						src={theme === 'light' ? logoLight : logoDark}
						alt='AdFrame Logo'
					/>
					<h2 className='mt-10 text-center font-bold text-back leading-9 tracking-tight sm:text-2xl 2xl:text-3xl'>
						{t('sign.confirm.headline')}
					</h2>
					<p
						className='my-6 text-center text-md'
						// biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
						dangerouslySetInnerHTML={{ __html: t('sign.confirm.description', { email }) }}
					/>
				</div>
				<div className='flex justify-center'>
					<InputOTP
						maxLength={6}
						inputMode='numeric'
						onComplete={onComplete}
						render={({ slots }) => (
							<>
								<InputOTPGroup>
									{slots.slice(0, 3).map((slot, index) => (
										<InputOTPSlot key={index.toString()} {...slot} />
									))}
								</InputOTPGroup>
								<InputOTPSeparator />
								<InputOTPGroup>
									{slots.slice(3).map((slot, index) => (
										<InputOTPSlot key={index.toString()} {...slot} />
									))}
								</InputOTPGroup>
							</>
						)}
					/>
				</div>
				<p className='text-center text-sm'>Can't see the email? Please check the spam folder.</p>
			</div>
		</>
	);
}
