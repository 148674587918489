/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as signLayoutImport } from './routes/sign/_layout'
import { Route as dashboardLayoutImport } from './routes/dashboard/_layout'
import { Route as dashboardMeIndexImport } from './routes/dashboard/me/index'
import { Route as signIndexImport } from './routes/sign/index'
import { Route as dashboardIndexImport } from './routes/dashboard/index'
import { Route as signUpImport } from './routes/sign/up'
import { Route as signOutImport } from './routes/sign/out'
import { Route as signHandleImport } from './routes/sign/handle'
import { Route as signConfirmImport } from './routes/sign/confirm'
import { Route as dashboardSettingsLayoutImport } from './routes/dashboard/settings/_layout'
import { Route as dashboardPlacementsLayoutImport } from './routes/dashboard/placements/_layout'
import { Route as dashboardCampaignsCampaignIdIndexImport } from './routes/dashboard/campaigns/$campaignId/index'
import { Route as dashboardPlacementsIndexImport } from './routes/dashboard/placements/index'
import { Route as dashboardCampaignsIndexImport } from './routes/dashboard/campaigns/index'
import { Route as signInvitationsinvitationIdImport } from './routes/sign/invitations.$invitationId'
import { Route as dashboardSettingsPreferenceImport } from './routes/dashboard/settings/preference'
import { Route as dashboardSettingsPeopleImport } from './routes/dashboard/settings/people'
import { Route as dashboardSettingsGeneralImport } from './routes/dashboard/settings/general'
import { Route as dashboardPlacementsGroupsImport } from './routes/dashboard/placements/groups'
import { Route as dashboardSettingsIntegrationIndexImport } from './routes/dashboard/settings/integration/index'
import { Route as dashboardSettingsIntegrationHandleImport } from './routes/dashboard/settings/integration/handle'
import { Route as dashboardCampaignsCampaignIdCreativesCreativeIdImport } from './routes/dashboard/campaigns/$campaignId/creatives/$creativeId'

// Create Virtual Routes

const DashboardCampaignsImport = createFileRoute('/_dashboard/campaigns')()
const DashboardSettingsIntegrationImport = createFileRoute(
  '/_dashboard/settings/integration',
)()

// Create/Update Routes

const signLayoutRoute = signLayoutImport.update({
  id: '/sign',
  path: '/sign',
  getParentRoute: () => rootRoute,
} as any)

const dashboardLayoutRoute = dashboardLayoutImport.update({
  id: '/_dashboard',
  getParentRoute: () => rootRoute,
} as any)

const dashboardMeIndexRoute = dashboardMeIndexImport.update({
  id: '/me',
  path: '/me',
  getParentRoute: () => dashboardLayoutRoute,
} as any)

const signIndexRoute = signIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => signLayoutRoute,
} as any)

const dashboardIndexRoute = dashboardIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => dashboardLayoutRoute,
} as any)

const signUpRoute = signUpImport.update({
  id: '/up',
  path: '/up',
  getParentRoute: () => signLayoutRoute,
} as any)

const signOutRoute = signOutImport.update({
  id: '/out',
  path: '/out',
  getParentRoute: () => signLayoutRoute,
} as any)

const signHandleRoute = signHandleImport.update({
  id: '/handle',
  path: '/handle',
  getParentRoute: () => signLayoutRoute,
} as any)

const signConfirmRoute = signConfirmImport.update({
  id: '/confirm',
  path: '/confirm',
  getParentRoute: () => signLayoutRoute,
} as any)

const dashboardSettingsLayoutRoute = dashboardSettingsLayoutImport.update({
  id: '/settings',
  path: '/settings',
  getParentRoute: () => dashboardLayoutRoute,
} as any)

const dashboardPlacementsLayoutRoute = dashboardPlacementsLayoutImport.update({
  id: '/placements',
  path: '/placements',
  getParentRoute: () => dashboardLayoutRoute,
} as any)

const DashboardCampaignsRoute = DashboardCampaignsImport.update({
  id: '/campaigns',
  path: '/campaigns',
  getParentRoute: () => dashboardLayoutRoute,
} as any)

const dashboardCampaignsCampaignIdIndexRoute =
  dashboardCampaignsCampaignIdIndexImport.update({
    id: '/$campaignId',
    path: '/$campaignId',
    getParentRoute: () => DashboardCampaignsRoute,
  } as any)

const dashboardPlacementsIndexRoute = dashboardPlacementsIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => dashboardPlacementsLayoutRoute,
} as any)

const dashboardCampaignsIndexRoute = dashboardCampaignsIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => DashboardCampaignsRoute,
} as any)

const signInvitationsinvitationIdRoute =
  signInvitationsinvitationIdImport.update({
    id: '/invitations/$invitationId',
    path: '/invitations/$invitationId',
    getParentRoute: () => signLayoutRoute,
  } as any)

const dashboardSettingsPreferenceRoute =
  dashboardSettingsPreferenceImport.update({
    id: '/preference',
    path: '/preference',
    getParentRoute: () => dashboardSettingsLayoutRoute,
  } as any)

const dashboardSettingsPeopleRoute = dashboardSettingsPeopleImport.update({
  id: '/people',
  path: '/people',
  getParentRoute: () => dashboardSettingsLayoutRoute,
} as any)

const DashboardSettingsIntegrationRoute =
  DashboardSettingsIntegrationImport.update({
    id: '/integration',
    path: '/integration',
    getParentRoute: () => dashboardSettingsLayoutRoute,
  } as any)

const dashboardSettingsGeneralRoute = dashboardSettingsGeneralImport.update({
  id: '/general',
  path: '/general',
  getParentRoute: () => dashboardSettingsLayoutRoute,
} as any)

const dashboardPlacementsGroupsRoute = dashboardPlacementsGroupsImport.update({
  id: '/groups',
  path: '/groups',
  getParentRoute: () => dashboardPlacementsLayoutRoute,
} as any)

const dashboardSettingsIntegrationIndexRoute =
  dashboardSettingsIntegrationIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => DashboardSettingsIntegrationRoute,
  } as any)

const dashboardSettingsIntegrationHandleRoute =
  dashboardSettingsIntegrationHandleImport.update({
    id: '/handle',
    path: '/handle',
    getParentRoute: () => DashboardSettingsIntegrationRoute,
  } as any)

const dashboardCampaignsCampaignIdCreativesCreativeIdRoute =
  dashboardCampaignsCampaignIdCreativesCreativeIdImport.update({
    id: '/creatives/$creativeId',
    path: '/creatives/$creativeId',
    getParentRoute: () => dashboardCampaignsCampaignIdIndexRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/_dashboard': {
      id: '/_dashboard'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof dashboardLayoutImport
      parentRoute: typeof rootRoute
    }
    '/sign': {
      id: '/sign'
      path: '/sign'
      fullPath: '/sign'
      preLoaderRoute: typeof signLayoutImport
      parentRoute: typeof rootRoute
    }
    '/_dashboard/campaigns': {
      id: '/_dashboard/campaigns'
      path: '/campaigns'
      fullPath: '/campaigns'
      preLoaderRoute: typeof DashboardCampaignsImport
      parentRoute: typeof dashboardLayoutImport
    }
    '/_dashboard/placements': {
      id: '/_dashboard/placements'
      path: '/placements'
      fullPath: '/placements'
      preLoaderRoute: typeof dashboardPlacementsLayoutImport
      parentRoute: typeof dashboardLayoutImport
    }
    '/_dashboard/settings': {
      id: '/_dashboard/settings'
      path: '/settings'
      fullPath: '/settings'
      preLoaderRoute: typeof dashboardSettingsLayoutImport
      parentRoute: typeof dashboardLayoutImport
    }
    '/sign/confirm': {
      id: '/sign/confirm'
      path: '/confirm'
      fullPath: '/sign/confirm'
      preLoaderRoute: typeof signConfirmImport
      parentRoute: typeof signLayoutImport
    }
    '/sign/handle': {
      id: '/sign/handle'
      path: '/handle'
      fullPath: '/sign/handle'
      preLoaderRoute: typeof signHandleImport
      parentRoute: typeof signLayoutImport
    }
    '/sign/out': {
      id: '/sign/out'
      path: '/out'
      fullPath: '/sign/out'
      preLoaderRoute: typeof signOutImport
      parentRoute: typeof signLayoutImport
    }
    '/sign/up': {
      id: '/sign/up'
      path: '/up'
      fullPath: '/sign/up'
      preLoaderRoute: typeof signUpImport
      parentRoute: typeof signLayoutImport
    }
    '/_dashboard/': {
      id: '/_dashboard/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof dashboardIndexImport
      parentRoute: typeof dashboardLayoutImport
    }
    '/sign/': {
      id: '/sign/'
      path: '/'
      fullPath: '/sign/'
      preLoaderRoute: typeof signIndexImport
      parentRoute: typeof signLayoutImport
    }
    '/_dashboard/me': {
      id: '/_dashboard/me'
      path: '/me'
      fullPath: '/me'
      preLoaderRoute: typeof dashboardMeIndexImport
      parentRoute: typeof dashboardLayoutImport
    }
    '/_dashboard/placements/groups': {
      id: '/_dashboard/placements/groups'
      path: '/groups'
      fullPath: '/placements/groups'
      preLoaderRoute: typeof dashboardPlacementsGroupsImport
      parentRoute: typeof dashboardPlacementsLayoutImport
    }
    '/_dashboard/settings/general': {
      id: '/_dashboard/settings/general'
      path: '/general'
      fullPath: '/settings/general'
      preLoaderRoute: typeof dashboardSettingsGeneralImport
      parentRoute: typeof dashboardSettingsLayoutImport
    }
    '/_dashboard/settings/integration': {
      id: '/_dashboard/settings/integration'
      path: '/integration'
      fullPath: '/settings/integration'
      preLoaderRoute: typeof DashboardSettingsIntegrationImport
      parentRoute: typeof dashboardSettingsLayoutImport
    }
    '/_dashboard/settings/people': {
      id: '/_dashboard/settings/people'
      path: '/people'
      fullPath: '/settings/people'
      preLoaderRoute: typeof dashboardSettingsPeopleImport
      parentRoute: typeof dashboardSettingsLayoutImport
    }
    '/_dashboard/settings/preference': {
      id: '/_dashboard/settings/preference'
      path: '/preference'
      fullPath: '/settings/preference'
      preLoaderRoute: typeof dashboardSettingsPreferenceImport
      parentRoute: typeof dashboardSettingsLayoutImport
    }
    '/sign/invitations/$invitationId': {
      id: '/sign/invitations/$invitationId'
      path: '/invitations/$invitationId'
      fullPath: '/sign/invitations/$invitationId'
      preLoaderRoute: typeof signInvitationsinvitationIdImport
      parentRoute: typeof signLayoutImport
    }
    '/_dashboard/campaigns/': {
      id: '/_dashboard/campaigns/'
      path: '/'
      fullPath: '/campaigns/'
      preLoaderRoute: typeof dashboardCampaignsIndexImport
      parentRoute: typeof DashboardCampaignsImport
    }
    '/_dashboard/placements/': {
      id: '/_dashboard/placements/'
      path: '/'
      fullPath: '/placements/'
      preLoaderRoute: typeof dashboardPlacementsIndexImport
      parentRoute: typeof dashboardPlacementsLayoutImport
    }
    '/_dashboard/campaigns/$campaignId': {
      id: '/_dashboard/campaigns/$campaignId'
      path: '/$campaignId'
      fullPath: '/campaigns/$campaignId'
      preLoaderRoute: typeof dashboardCampaignsCampaignIdIndexImport
      parentRoute: typeof DashboardCampaignsImport
    }
    '/_dashboard/settings/integration/handle': {
      id: '/_dashboard/settings/integration/handle'
      path: '/handle'
      fullPath: '/settings/integration/handle'
      preLoaderRoute: typeof dashboardSettingsIntegrationHandleImport
      parentRoute: typeof DashboardSettingsIntegrationImport
    }
    '/_dashboard/settings/integration/': {
      id: '/_dashboard/settings/integration/'
      path: '/'
      fullPath: '/settings/integration/'
      preLoaderRoute: typeof dashboardSettingsIntegrationIndexImport
      parentRoute: typeof DashboardSettingsIntegrationImport
    }
    '/_dashboard/campaigns/$campaignId/creatives/$creativeId': {
      id: '/_dashboard/campaigns/$campaignId/creatives/$creativeId'
      path: '/creatives/$creativeId'
      fullPath: '/campaigns/$campaignId/creatives/$creativeId'
      preLoaderRoute: typeof dashboardCampaignsCampaignIdCreativesCreativeIdImport
      parentRoute: typeof dashboardCampaignsCampaignIdIndexImport
    }
  }
}

// Create and export the route tree

interface dashboardCampaignsCampaignIdIndexRouteChildren {
  dashboardCampaignsCampaignIdCreativesCreativeIdRoute: typeof dashboardCampaignsCampaignIdCreativesCreativeIdRoute
}

const dashboardCampaignsCampaignIdIndexRouteChildren: dashboardCampaignsCampaignIdIndexRouteChildren =
  {
    dashboardCampaignsCampaignIdCreativesCreativeIdRoute:
      dashboardCampaignsCampaignIdCreativesCreativeIdRoute,
  }

const dashboardCampaignsCampaignIdIndexRouteWithChildren =
  dashboardCampaignsCampaignIdIndexRoute._addFileChildren(
    dashboardCampaignsCampaignIdIndexRouteChildren,
  )

interface DashboardCampaignsRouteChildren {
  dashboardCampaignsIndexRoute: typeof dashboardCampaignsIndexRoute
  dashboardCampaignsCampaignIdIndexRoute: typeof dashboardCampaignsCampaignIdIndexRouteWithChildren
}

const DashboardCampaignsRouteChildren: DashboardCampaignsRouteChildren = {
  dashboardCampaignsIndexRoute: dashboardCampaignsIndexRoute,
  dashboardCampaignsCampaignIdIndexRoute:
    dashboardCampaignsCampaignIdIndexRouteWithChildren,
}

const DashboardCampaignsRouteWithChildren =
  DashboardCampaignsRoute._addFileChildren(DashboardCampaignsRouteChildren)

interface dashboardPlacementsLayoutRouteChildren {
  dashboardPlacementsGroupsRoute: typeof dashboardPlacementsGroupsRoute
  dashboardPlacementsIndexRoute: typeof dashboardPlacementsIndexRoute
}

const dashboardPlacementsLayoutRouteChildren: dashboardPlacementsLayoutRouteChildren =
  {
    dashboardPlacementsGroupsRoute: dashboardPlacementsGroupsRoute,
    dashboardPlacementsIndexRoute: dashboardPlacementsIndexRoute,
  }

const dashboardPlacementsLayoutRouteWithChildren =
  dashboardPlacementsLayoutRoute._addFileChildren(
    dashboardPlacementsLayoutRouteChildren,
  )

interface DashboardSettingsIntegrationRouteChildren {
  dashboardSettingsIntegrationHandleRoute: typeof dashboardSettingsIntegrationHandleRoute
  dashboardSettingsIntegrationIndexRoute: typeof dashboardSettingsIntegrationIndexRoute
}

const DashboardSettingsIntegrationRouteChildren: DashboardSettingsIntegrationRouteChildren =
  {
    dashboardSettingsIntegrationHandleRoute:
      dashboardSettingsIntegrationHandleRoute,
    dashboardSettingsIntegrationIndexRoute:
      dashboardSettingsIntegrationIndexRoute,
  }

const DashboardSettingsIntegrationRouteWithChildren =
  DashboardSettingsIntegrationRoute._addFileChildren(
    DashboardSettingsIntegrationRouteChildren,
  )

interface dashboardSettingsLayoutRouteChildren {
  dashboardSettingsGeneralRoute: typeof dashboardSettingsGeneralRoute
  DashboardSettingsIntegrationRoute: typeof DashboardSettingsIntegrationRouteWithChildren
  dashboardSettingsPeopleRoute: typeof dashboardSettingsPeopleRoute
  dashboardSettingsPreferenceRoute: typeof dashboardSettingsPreferenceRoute
}

const dashboardSettingsLayoutRouteChildren: dashboardSettingsLayoutRouteChildren =
  {
    dashboardSettingsGeneralRoute: dashboardSettingsGeneralRoute,
    DashboardSettingsIntegrationRoute:
      DashboardSettingsIntegrationRouteWithChildren,
    dashboardSettingsPeopleRoute: dashboardSettingsPeopleRoute,
    dashboardSettingsPreferenceRoute: dashboardSettingsPreferenceRoute,
  }

const dashboardSettingsLayoutRouteWithChildren =
  dashboardSettingsLayoutRoute._addFileChildren(
    dashboardSettingsLayoutRouteChildren,
  )

interface dashboardLayoutRouteChildren {
  DashboardCampaignsRoute: typeof DashboardCampaignsRouteWithChildren
  dashboardPlacementsLayoutRoute: typeof dashboardPlacementsLayoutRouteWithChildren
  dashboardSettingsLayoutRoute: typeof dashboardSettingsLayoutRouteWithChildren
  dashboardIndexRoute: typeof dashboardIndexRoute
  dashboardMeIndexRoute: typeof dashboardMeIndexRoute
}

const dashboardLayoutRouteChildren: dashboardLayoutRouteChildren = {
  DashboardCampaignsRoute: DashboardCampaignsRouteWithChildren,
  dashboardPlacementsLayoutRoute: dashboardPlacementsLayoutRouteWithChildren,
  dashboardSettingsLayoutRoute: dashboardSettingsLayoutRouteWithChildren,
  dashboardIndexRoute: dashboardIndexRoute,
  dashboardMeIndexRoute: dashboardMeIndexRoute,
}

const dashboardLayoutRouteWithChildren = dashboardLayoutRoute._addFileChildren(
  dashboardLayoutRouteChildren,
)

interface signLayoutRouteChildren {
  signConfirmRoute: typeof signConfirmRoute
  signHandleRoute: typeof signHandleRoute
  signOutRoute: typeof signOutRoute
  signUpRoute: typeof signUpRoute
  signIndexRoute: typeof signIndexRoute
  signInvitationsinvitationIdRoute: typeof signInvitationsinvitationIdRoute
}

const signLayoutRouteChildren: signLayoutRouteChildren = {
  signConfirmRoute: signConfirmRoute,
  signHandleRoute: signHandleRoute,
  signOutRoute: signOutRoute,
  signUpRoute: signUpRoute,
  signIndexRoute: signIndexRoute,
  signInvitationsinvitationIdRoute: signInvitationsinvitationIdRoute,
}

const signLayoutRouteWithChildren = signLayoutRoute._addFileChildren(
  signLayoutRouteChildren,
)

export interface FileRoutesByFullPath {
  '': typeof dashboardLayoutRouteWithChildren
  '/sign': typeof signLayoutRouteWithChildren
  '/campaigns': typeof DashboardCampaignsRouteWithChildren
  '/placements': typeof dashboardPlacementsLayoutRouteWithChildren
  '/settings': typeof dashboardSettingsLayoutRouteWithChildren
  '/sign/confirm': typeof signConfirmRoute
  '/sign/handle': typeof signHandleRoute
  '/sign/out': typeof signOutRoute
  '/sign/up': typeof signUpRoute
  '/': typeof dashboardIndexRoute
  '/sign/': typeof signIndexRoute
  '/me': typeof dashboardMeIndexRoute
  '/placements/groups': typeof dashboardPlacementsGroupsRoute
  '/settings/general': typeof dashboardSettingsGeneralRoute
  '/settings/integration': typeof DashboardSettingsIntegrationRouteWithChildren
  '/settings/people': typeof dashboardSettingsPeopleRoute
  '/settings/preference': typeof dashboardSettingsPreferenceRoute
  '/sign/invitations/$invitationId': typeof signInvitationsinvitationIdRoute
  '/campaigns/': typeof dashboardCampaignsIndexRoute
  '/placements/': typeof dashboardPlacementsIndexRoute
  '/campaigns/$campaignId': typeof dashboardCampaignsCampaignIdIndexRouteWithChildren
  '/settings/integration/handle': typeof dashboardSettingsIntegrationHandleRoute
  '/settings/integration/': typeof dashboardSettingsIntegrationIndexRoute
  '/campaigns/$campaignId/creatives/$creativeId': typeof dashboardCampaignsCampaignIdCreativesCreativeIdRoute
}

export interface FileRoutesByTo {
  '/settings': typeof dashboardSettingsLayoutRouteWithChildren
  '/sign/confirm': typeof signConfirmRoute
  '/sign/handle': typeof signHandleRoute
  '/sign/out': typeof signOutRoute
  '/sign/up': typeof signUpRoute
  '/': typeof dashboardIndexRoute
  '/sign': typeof signIndexRoute
  '/me': typeof dashboardMeIndexRoute
  '/placements/groups': typeof dashboardPlacementsGroupsRoute
  '/settings/general': typeof dashboardSettingsGeneralRoute
  '/settings/people': typeof dashboardSettingsPeopleRoute
  '/settings/preference': typeof dashboardSettingsPreferenceRoute
  '/sign/invitations/$invitationId': typeof signInvitationsinvitationIdRoute
  '/campaigns': typeof dashboardCampaignsIndexRoute
  '/placements': typeof dashboardPlacementsIndexRoute
  '/campaigns/$campaignId': typeof dashboardCampaignsCampaignIdIndexRouteWithChildren
  '/settings/integration/handle': typeof dashboardSettingsIntegrationHandleRoute
  '/settings/integration': typeof dashboardSettingsIntegrationIndexRoute
  '/campaigns/$campaignId/creatives/$creativeId': typeof dashboardCampaignsCampaignIdCreativesCreativeIdRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/_dashboard': typeof dashboardLayoutRouteWithChildren
  '/sign': typeof signLayoutRouteWithChildren
  '/_dashboard/campaigns': typeof DashboardCampaignsRouteWithChildren
  '/_dashboard/placements': typeof dashboardPlacementsLayoutRouteWithChildren
  '/_dashboard/settings': typeof dashboardSettingsLayoutRouteWithChildren
  '/sign/confirm': typeof signConfirmRoute
  '/sign/handle': typeof signHandleRoute
  '/sign/out': typeof signOutRoute
  '/sign/up': typeof signUpRoute
  '/_dashboard/': typeof dashboardIndexRoute
  '/sign/': typeof signIndexRoute
  '/_dashboard/me': typeof dashboardMeIndexRoute
  '/_dashboard/placements/groups': typeof dashboardPlacementsGroupsRoute
  '/_dashboard/settings/general': typeof dashboardSettingsGeneralRoute
  '/_dashboard/settings/integration': typeof DashboardSettingsIntegrationRouteWithChildren
  '/_dashboard/settings/people': typeof dashboardSettingsPeopleRoute
  '/_dashboard/settings/preference': typeof dashboardSettingsPreferenceRoute
  '/sign/invitations/$invitationId': typeof signInvitationsinvitationIdRoute
  '/_dashboard/campaigns/': typeof dashboardCampaignsIndexRoute
  '/_dashboard/placements/': typeof dashboardPlacementsIndexRoute
  '/_dashboard/campaigns/$campaignId': typeof dashboardCampaignsCampaignIdIndexRouteWithChildren
  '/_dashboard/settings/integration/handle': typeof dashboardSettingsIntegrationHandleRoute
  '/_dashboard/settings/integration/': typeof dashboardSettingsIntegrationIndexRoute
  '/_dashboard/campaigns/$campaignId/creatives/$creativeId': typeof dashboardCampaignsCampaignIdCreativesCreativeIdRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | ''
    | '/sign'
    | '/campaigns'
    | '/placements'
    | '/settings'
    | '/sign/confirm'
    | '/sign/handle'
    | '/sign/out'
    | '/sign/up'
    | '/'
    | '/sign/'
    | '/me'
    | '/placements/groups'
    | '/settings/general'
    | '/settings/integration'
    | '/settings/people'
    | '/settings/preference'
    | '/sign/invitations/$invitationId'
    | '/campaigns/'
    | '/placements/'
    | '/campaigns/$campaignId'
    | '/settings/integration/handle'
    | '/settings/integration/'
    | '/campaigns/$campaignId/creatives/$creativeId'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/settings'
    | '/sign/confirm'
    | '/sign/handle'
    | '/sign/out'
    | '/sign/up'
    | '/'
    | '/sign'
    | '/me'
    | '/placements/groups'
    | '/settings/general'
    | '/settings/people'
    | '/settings/preference'
    | '/sign/invitations/$invitationId'
    | '/campaigns'
    | '/placements'
    | '/campaigns/$campaignId'
    | '/settings/integration/handle'
    | '/settings/integration'
    | '/campaigns/$campaignId/creatives/$creativeId'
  id:
    | '__root__'
    | '/_dashboard'
    | '/sign'
    | '/_dashboard/campaigns'
    | '/_dashboard/placements'
    | '/_dashboard/settings'
    | '/sign/confirm'
    | '/sign/handle'
    | '/sign/out'
    | '/sign/up'
    | '/_dashboard/'
    | '/sign/'
    | '/_dashboard/me'
    | '/_dashboard/placements/groups'
    | '/_dashboard/settings/general'
    | '/_dashboard/settings/integration'
    | '/_dashboard/settings/people'
    | '/_dashboard/settings/preference'
    | '/sign/invitations/$invitationId'
    | '/_dashboard/campaigns/'
    | '/_dashboard/placements/'
    | '/_dashboard/campaigns/$campaignId'
    | '/_dashboard/settings/integration/handle'
    | '/_dashboard/settings/integration/'
    | '/_dashboard/campaigns/$campaignId/creatives/$creativeId'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  dashboardLayoutRoute: typeof dashboardLayoutRouteWithChildren
  signLayoutRoute: typeof signLayoutRouteWithChildren
}

const rootRouteChildren: RootRouteChildren = {
  dashboardLayoutRoute: dashboardLayoutRouteWithChildren,
  signLayoutRoute: signLayoutRouteWithChildren,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/_dashboard",
        "/sign"
      ]
    },
    "/_dashboard": {
      "filePath": "dashboard/_layout.tsx",
      "children": [
        "/_dashboard/campaigns",
        "/_dashboard/placements",
        "/_dashboard/settings",
        "/_dashboard/",
        "/_dashboard/me"
      ]
    },
    "/sign": {
      "filePath": "sign/_layout.tsx",
      "children": [
        "/sign/confirm",
        "/sign/handle",
        "/sign/out",
        "/sign/up",
        "/sign/",
        "/sign/invitations/$invitationId"
      ]
    },
    "/_dashboard/campaigns": {
      "filePath": "",
      "parent": "/_dashboard",
      "children": [
        "/_dashboard/campaigns/",
        "/_dashboard/campaigns/$campaignId"
      ]
    },
    "/_dashboard/placements": {
      "filePath": "dashboard/placements/_layout.tsx",
      "parent": "/_dashboard",
      "children": [
        "/_dashboard/placements/groups",
        "/_dashboard/placements/"
      ]
    },
    "/_dashboard/settings": {
      "filePath": "dashboard/settings/_layout.tsx",
      "parent": "/_dashboard",
      "children": [
        "/_dashboard/settings/general",
        "/_dashboard/settings/integration",
        "/_dashboard/settings/people",
        "/_dashboard/settings/preference"
      ]
    },
    "/sign/confirm": {
      "filePath": "sign/confirm.tsx",
      "parent": "/sign"
    },
    "/sign/handle": {
      "filePath": "sign/handle.tsx",
      "parent": "/sign"
    },
    "/sign/out": {
      "filePath": "sign/out.tsx",
      "parent": "/sign"
    },
    "/sign/up": {
      "filePath": "sign/up.tsx",
      "parent": "/sign"
    },
    "/_dashboard/": {
      "filePath": "dashboard/index.tsx",
      "parent": "/_dashboard"
    },
    "/sign/": {
      "filePath": "sign/index.tsx",
      "parent": "/sign"
    },
    "/_dashboard/me": {
      "filePath": "dashboard/me/index.tsx",
      "parent": "/_dashboard"
    },
    "/_dashboard/placements/groups": {
      "filePath": "dashboard/placements/groups.tsx",
      "parent": "/_dashboard/placements"
    },
    "/_dashboard/settings/general": {
      "filePath": "dashboard/settings/general.tsx",
      "parent": "/_dashboard/settings"
    },
    "/_dashboard/settings/integration": {
      "filePath": "",
      "parent": "/_dashboard/settings",
      "children": [
        "/_dashboard/settings/integration/handle",
        "/_dashboard/settings/integration/"
      ]
    },
    "/_dashboard/settings/people": {
      "filePath": "dashboard/settings/people.tsx",
      "parent": "/_dashboard/settings"
    },
    "/_dashboard/settings/preference": {
      "filePath": "dashboard/settings/preference.tsx",
      "parent": "/_dashboard/settings"
    },
    "/sign/invitations/$invitationId": {
      "filePath": "sign/invitations.$invitationId.tsx",
      "parent": "/sign"
    },
    "/_dashboard/campaigns/": {
      "filePath": "dashboard/campaigns/index.tsx",
      "parent": "/_dashboard/campaigns"
    },
    "/_dashboard/placements/": {
      "filePath": "dashboard/placements/index.tsx",
      "parent": "/_dashboard/placements"
    },
    "/_dashboard/campaigns/$campaignId": {
      "filePath": "dashboard/campaigns/$campaignId/index.tsx",
      "parent": "/_dashboard/campaigns",
      "children": [
        "/_dashboard/campaigns/$campaignId/creatives/$creativeId"
      ]
    },
    "/_dashboard/settings/integration/handle": {
      "filePath": "dashboard/settings/integration/handle.tsx",
      "parent": "/_dashboard/settings/integration"
    },
    "/_dashboard/settings/integration/": {
      "filePath": "dashboard/settings/integration/index.tsx",
      "parent": "/_dashboard/settings/integration"
    },
    "/_dashboard/campaigns/$campaignId/creatives/$creativeId": {
      "filePath": "dashboard/campaigns/$campaignId/creatives/$creativeId.tsx",
      "parent": "/_dashboard/campaigns/$campaignId"
    }
  }
}
ROUTE_MANIFEST_END */
