import { useTranslation } from '@adframe/translation';
import React, { type PropsWithChildren, createContext, useContext, useState } from 'react';

import { Button } from '@adframe/ui/components/button';
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from '@adframe/ui/components/dialog';

type DialogOptions = DialogBase | React.ReactNode;

type DialogBase = {
	confirmText?: string;
	cancelText?: string;
	onConfirm?: () => void;
	onCancel?: () => void;
	title: string;
	description: string | React.ReactNode;
};

type DialogContextType = {
	showDialog: (options: DialogOptions) => void;
	hideDialog: () => void;
};

export const DialogContext = createContext<DialogContextType | undefined>(undefined);

export function DialogProvider<P = unknown>({ children }: PropsWithChildren<P>) {
	const [dialogOptions, setDialogOptions] = useState<DialogOptions | null>(null);
	const { t } = useTranslation();

	const showDialog = (options: DialogOptions) => {
		setDialogOptions(options);
	};

	const hideDialog = () => {
		setDialogOptions(null);
	};

	const handleConfirm = () => {
		dialogOptions &&
			typeof dialogOptions === 'object' &&
			'onConfirm' in dialogOptions &&
			dialogOptions?.onConfirm?.();
		hideDialog();
	};

	const handleCancel = () => {
		dialogOptions &&
			typeof dialogOptions === 'object' &&
			'onCancel' in dialogOptions &&
			dialogOptions?.onCancel?.();
		hideDialog();
	};

	const onOpenChange = (open: boolean) => {
		if (!open) {
			setTimeout(() => hideDialog(), 200);
		}
	};

	return (
		<DialogContext.Provider value={{ showDialog, hideDialog }}>
			{children}
			<Dialog open={!!dialogOptions} onOpenChange={onOpenChange}>
				{dialogOptions && React.isValidElement(dialogOptions) && (
					<DialogContent className='sm:max-w-md'>{dialogOptions}</DialogContent>
				)}

				{dialogOptions && typeof dialogOptions === 'object' && 'title' in dialogOptions && (
					<DialogContent className='sm:max-w-md'>
						<DialogHeader>
							<DialogTitle>{dialogOptions?.title}</DialogTitle>
							<DialogDescription className='text-sm'>
								{dialogOptions?.description}
							</DialogDescription>
						</DialogHeader>
						<DialogFooter className='gap-2 sm:gap-0'>
							<Button variant='outline' onClick={handleCancel}>
								{dialogOptions?.cancelText || t('dialog.cancel')}
							</Button>
							<Button onClick={handleConfirm}>
								{dialogOptions?.confirmText ?? t('dialog.continue')}
							</Button>
						</DialogFooter>
					</DialogContent>
				)}
			</Dialog>
		</DialogContext.Provider>
	);
}

export const useDialog = () => {
	const context = useContext(DialogContext);
	if (!context) {
		throw new Error('useDialog must be used within a DialogProvider');
	}
	return context;
};

export default DialogProvider;
