import { createFileRoute } from '@tanstack/react-router';
import { Outlet } from '@tanstack/react-router';

import PlacementMenu from './components/PlacementMenu';

export const Route = createFileRoute('/_dashboard/placements')({
	component: RouteComponent,
});

function RouteComponent() {
	return (
		<>
			<div className='container mx-auto flex flex-col gap-6 py-10'>
				<div>
					<PlacementMenu />
				</div>
				<div className='min-h-[75vh]'>
					<Outlet />
				</div>
			</div>
		</>
	);
}
