import { queryOptions } from '@tanstack/react-query';

import getApiClient from '../services/internal-api/get-api-client';

export const getCreativeQueryOptions = (creativeId: string) =>
	queryOptions({
		queryKey: ['creatives', creativeId],
		staleTime: 10_000,
		queryFn: () =>
			getApiClient()
				.creatives[':creativeId'].$get({
					param: {
						creativeId,
					},
				})
				.then((result) => result.json()),
	});

export const listCreativesFromCampaignQueryOptions = (campaignId: string) =>
	queryOptions({
		queryKey: ['campaigns', campaignId, 'creatives'],
		staleTime: 10_000,
		queryFn: () =>
			getApiClient()
				.creatives.$get({
					query: {
						campaignId,
					},
				})
				.then((result) => result.json()),
	});
