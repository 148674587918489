import { createFileRoute, redirect } from '@tanstack/react-router';
import { Link, Outlet } from '@tanstack/react-router';
import { CreditCard, PlugZap, Settings, Settings2, Users } from 'lucide-react';
import { Route as generalSettingsRoute } from './general';
import { Route as integrationSettingsRoute } from './integration';
import { Route as peopleSettingsRoute } from './people';
import { Route as preferenceSettingsRoute } from './preference';

import { useTranslation } from '@adframe/translation';

import { Button } from '@adframe/ui/components/button';
import useAccess from '#app/hooks/use-access.js';
import getAccountClient from '#app/services/internal-api/get-account-client.js';

export const Route = createFileRoute('/_dashboard/settings')({
	component: RouteComponent,
	beforeLoad: ({ location }) => {
		if (location.pathname === '/settings') {
			throw redirect({
				to: '/settings/general',
			});
		}
	},
});

function RouteComponent() {
	const { t } = useTranslation();
	const { hasPermission } = useAccess();

	const {
		subscriptions: { portal },
	} = getAccountClient();

	const settingsNavigation = [
		{
			id: 'general',
			route: generalSettingsRoute,
			icon: Settings,
			access: hasPermission('organization'),
		},
		{
			id: 'preference',
			route: preferenceSettingsRoute,
			icon: Settings2,
			access: hasPermission('preference'),
		},
		{
			id: 'people',
			route: peopleSettingsRoute,
			icon: Users,
			access: hasPermission('user'),
		},
		{
			id: 'integration',
			route: integrationSettingsRoute,
			icon: PlugZap,
			access: hasPermission('integration'),
		},
		{
			id: 'billing',
			route: portal.$url().href,
			icon: CreditCard,
			access: hasPermission('billing'),
		},
	] as const;

	return (
		<div className='flex'>
			<aside className='inset-y-0 hidden min-h-screen w-2/3 border-border border-r px-4 py-6 sm:block sm:px-6 lg:w-1/5 lg:px-8'>
				<nav className='sticky top-0 flex flex-1 flex-col' aria-label='Sidebar'>
					<ul className='-mx-2 space-y-1'>
						{settingsNavigation
							.filter(({ access }) => access)
							.map((item) => (
								<li key={item.id}>
									{typeof item.route === 'string' ? (
										<>
											<Button asChild variant={'ghost'} className='w-full justify-start'>
												<a href={item.route}>
													<item.icon aria-hidden='true' />
													{t(`settings.layout.${item.id}`)}
												</a>
											</Button>
										</>
									) : (
										<Button asChild variant={'ghost'} className='w-full justify-start'>
											<Link
												to={item.route.to}
												from='/settings'
												search=''
												activeProps={{ 'data-state': 'active' }}
											>
												<item.icon aria-hidden='true' />
												{t(`settings.layout.${item.id}`)}
											</Link>
										</Button>
									)}
								</li>
							))}
					</ul>
				</nav>
			</aside>
			<main className='container max-w-5xl pt-12'>
				<Outlet />
			</main>
		</div>
	);
}
