import { zodResolver } from '@hookform/resolvers/zod';
import { Link, createFileRoute, useNavigate } from '@tanstack/react-router';
import { useForm } from 'react-hook-form';
import { toast } from 'sonner';
import { z } from 'zod';

import { useTranslation } from '@adframe/translation';
import { Button } from '@adframe/ui/components/button';
import { Form, FormControl, FormField, FormItem, FormMessage } from '@adframe/ui/components/form';
import { Input } from '@adframe/ui/components/input';
import { Separator } from '@adframe/ui/components/separator';
import useAuth from '#app/hooks/use-auth.js';
import useOpenWindow from '#app/hooks/use-open-window.js';
import getSecurityClient from '#app/services/internal-api/get-security-client.js';

import logoDark from '#app/assets/adframe-logo-dark-mode.png';
import logoLight from '#app/assets/adframe-logo-light-mode.png';
import GoogleLogo from '#app/assets/google.svg';
import { useTheme } from '#app/providers/theme.tsx';

export const Route = createFileRoute('/sign/')({
	component: RouteComponent,
	staticData: {
		guest: true,
	},
});

const formSchema = z.object({
	email: z.string().email(),
});

function RouteComponent() {
	const { signWithCode, signWithGoogle } = useAuth();

	const client = getSecurityClient();
	const { t } = useTranslation();
	const navigate = useNavigate({ from: '/sign' });
	const { theme } = useTheme();

	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			email: '',
		},
	});

	const { open } = useOpenWindow<{
		code: string;
		state: string;
		scope: string;
	}>('authenticate', async (message) => {
		const result = await signWithGoogle(message);

		if (result) {
			await navigate({ to: '/' });
		} else {
			toast.error("Something went wrong. User may does't exists.", {
				position: 'top-center',
			});
		}
	});

	function onSignWithGoogle() {
		open(`${client.google.$url()}?r=${Date.now()}`);
	}

	async function onSubmit(values: z.infer<typeof formSchema>) {
		try {
			await signWithCode(values);
			toast.success('A code has been sent', { position: 'top-center' });
			navigate({ to: '/sign/confirm', search: { email: values.email } });
		} catch (error) {
			toast.error('Something went wrong', { position: 'top-center' });
		}
	}

	return (
		<>
			<div className='sm:mx-auto sm:w-full sm:max-w-sm'>
				<img
					className='mx-auto h-10 w-auto'
					src={theme === 'light' ? logoLight : logoDark}
					alt='AdFrame Logo'
				/>
				<h2 className='mt-10 text-center font-bold text-foreground text-xl leading-9 tracking-tight'>
					{t('sign.title')}
				</h2>
			</div>

			<div className='mt-10 sm:mx-auto sm:w-full sm:max-w-sm'>
				<Form {...form}>
					<form onSubmit={form.handleSubmit(onSubmit)} className='space-y-8'>
						<FormField
							control={form.control}
							name='email'
							render={({ field }) => (
								<FormItem>
									<FormControl>
										<Input placeholder='john@doe.co' autoComplete='off' {...field} />
									</FormControl>

									<FormMessage />
								</FormItem>
							)}
						/>
						<Button type='submit' className='w-full'>
							{t('sign.login')}
						</Button>
					</form>
				</Form>
				<Separator className='my-6' />
				<div>
					<Button onClick={onSignWithGoogle} type='button' variant={'outline'} className='w-full'>
						<img className='h-4 px-3' src={GoogleLogo} alt='google Logo' />

						{t('sign.with_google')}
					</Button>
				</div>
				<div className='mt-6'>
					<Link to='/sign/up'>
						<Button type='button' variant={'outline'} className='w-full'>
							{t('sign.register')}
						</Button>
					</Link>
				</div>
			</div>
		</>
	);
}
