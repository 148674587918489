import { createContext, useCallback, useContext, useEffect, useState } from 'react';

type Theme = 'dark' | 'light';

type ThemeProviderProps = {
	children: React.ReactNode;
	defaultTheme?: Theme | 'system';
	storageKey?: string;
};

type ThemeProviderState = {
	theme: 'light' | 'dark';
	setTheme: (theme: Theme | 'system') => void;
};

const initialState: ThemeProviderState = {
	theme: 'light',
	setTheme: () => null,
};

const ThemeProviderContext = createContext<ThemeProviderState>(initialState);

export function ThemeProvider({
	children,
	defaultTheme = 'system',
	storageKey = 'af-theme',
	...props
}: ThemeProviderProps) {
	const [theme, set] = useState<Theme>('light');

	const setTheme = useCallback((theme: Theme | 'system') => {
		const root = window.document.documentElement;

		root.classList.remove('light', 'dark');

		if (theme === 'system') {
			const systemTheme = window.matchMedia('(prefers-color-scheme: dark)').matches
				? 'dark'
				: 'light';

			set(systemTheme);
			localStorage.setItem(storageKey, systemTheme);
			root.classList.add(systemTheme);
			return;
		}

		localStorage.setItem(storageKey, theme);
		root.classList.add(theme);
		set(theme);
	}, []);

	useEffect(() => {
		setTheme((localStorage.getItem(storageKey) as Theme) ?? defaultTheme);
	}, []);

	return (
		<ThemeProviderContext.Provider
			{...props}
			value={{
				theme,
				setTheme,
			}}
		>
			{children}
		</ThemeProviderContext.Provider>
	);
}

export const useTheme = () => {
	const context = useContext(ThemeProviderContext);

	if (context === undefined) throw new Error('useTheme must be used within a ThemeProvider');

	return context;
};
