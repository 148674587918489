import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enGB from './en-GB/index.js';
import enUs from './en-US/index.js';
import frFr from './fr-FR/index.js';

const resources = {
	'en-US': enUs,
	'fr-FR': frFr,
	'en-GB': enGB,
} as const;

export const availableLocales = Object.keys(resources) as Array<keyof typeof resources>;

export const defaultNS = 'common' as const;

declare module 'i18next' {
	interface CustomTypeOptions {
		defaultNS: typeof defaultNS;
		resources: (typeof resources)['en-US'];
	}
}

export const initI18n = (debug = false) => {
	i18n.use(initReactI18next).init({
		resources,
		defaultNS,
		ns: ['common'],
		fallbackNS: defaultNS,
		supportedLngs: ['en', 'en-US', 'fr-FR', 'en-GB'],
		fallbackLng: {
			en: ['en-US', 'en-GB'],
			default: ['en-US'],
		},
		lng: 'en-US',
		debug,
	});

	i18n.services.formatter?.add('ratio', (value: [number, number]) => {
		return `${value[0]}:${value[1]}`;
	});

	i18n.services.formatter?.add('size', (value: number) => {
		return value < 1024 * 1024
			? `${Math.floor(value / 1024).toString()}KB`
			: `${(Math.round((value / (1024 * 1024)) * 100) / 100).toString()}MB`;
	});

	return i18n;
};

export { useTranslation, getI18n } from 'react-i18next';
