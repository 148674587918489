import { useSuspenseQuery } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import { useDocumentTitle } from '@uidotdev/usehooks';

import Variation from '#app/components/variation/Variation.js';
import { getCreativeQueryOptions } from '#app/queries/creative.js';

import { Separator } from '@adframe/ui/components/separator';
import { redirect } from '@tanstack/react-router';
import { objectify } from 'radash';
import { useMemo } from 'react';
import usePlacement from '#app/hooks/use-placement.ts';
import Brief from './components/CreativeBrief';
import Versions from './components/version/Versions';
import { CreativeProvider } from './contexts';

export const Route = createFileRoute('/_dashboard/campaigns/$campaignId/creatives/$creativeId')({
	component: RouteComponent,
	staleTime: 0,
	validateSearch: (search): { version?: `ver_${string}` | undefined } => ({
		version: search.version as `ver_${string}` | undefined,
	}),
	loaderDeps: ({ search: { version } }) => ({ version }),
	loader: async ({ params, deps, context, preload }) => {
		const creative = await context.queryClient.fetchQuery(
			getCreativeQueryOptions(params.creativeId),
		);

		const firstVersion = creative.versions.sort((a, b) => a.createdAt - b.createdAt).at(0);

		const isInCreative = creative.versions.some((version) => version.id === deps.version);

		if ((!deps.version || !isInCreative) && firstVersion && !preload) {
			throw redirect({
				to: '/campaigns/$campaignId/creatives/$creativeId',
				params,
				search: {
					version: firstVersion.id,
				},
			});
		}

		return creative;
	},
});

function RouteComponent() {
	const { creativeId } = Route.useParams();
	const { version: versionId } = Route.useLoaderDeps();
	const { placementsById } = usePlacement();

	const { data: creative } = useSuspenseQuery(getCreativeQueryOptions(creativeId));

	const versionById = useMemo(
		() => objectify(creative.versions, ({ id }) => id),
		[creative.versions],
	);

	const version = useMemo(
		() => versionId && versionById[versionId],
		[versionId, creativeId, versionById],
	);

	useDocumentTitle(`AdFrame - ${creative?.name}`);

	return (
		<>
			<div className='flex flex-col 3xl:gap-10 lg:gap-6'>
				<div className='flex flex-row space-x-4 border-border border-b bg-background py-2 pl-6'>
					<div className='relative'>
						{!!creative.description?.length && (
							<div className='-top-1 -right-1 absolute rounded-full border border-border bg-background px-[4px] text-[10px]'>
								1
							</div>
						)}
						<Brief creative={creative} />
					</div>
					<Separator orientation='vertical' className='mt-2 mr-2 h-5' />
					<div>
						<Versions creativeId={creative.id} versions={creative.versions} />
					</div>
				</div>
				<div className='3xl:px-10 lg:px-6'>
					<CreativeProvider value={creative}>
						{!!version && (
							<div className='flex flex-col 3xl:gap-10 gap-4 lg:gap-6'>
								<div className='grid 3xl:grid-cols-5 gap-3 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4'>
									{creative.variations.map((variation) => (
										<Variation
											placement={
												variation.placementId ? placementsById[variation.placementId] : undefined
											}
											key={variation.id}
											variation={variation}
											version={version}
											assetAssignments={version.assetAssignments}
										/>
									))}
								</div>
							</div>
						)}
					</CreativeProvider>
				</div>
			</div>
		</>
	);
}
