import { useQuery } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import type { RowSelectionState, Updater } from '@tanstack/react-table';
import { Plus } from 'lucide-react';
import { useCallback, useState } from 'react';

import type { PlacementGroupWithPlacements } from '@adframe/database-adframe/types';
import { useTranslation } from '@adframe/translation';
import { Button } from '@adframe/ui/components/button';
import { DataTable } from '@adframe/ui/components/data-table';
import useAccess from '#app/hooks/use-access.js';
import usePlacementGroup, { placementGroupsQueryOptions } from '#app/hooks/use-placement-group.js';
import usePlacement from '#app/hooks/use-placement.js';
import { useSheet } from '#app/providers/sheet.js';

import { placementGroupColumns } from './columns/placement-group';
import PlacementGroupForm, { type PlacementGroupFormOutput } from './components/PlacementGroupForm';

export const Route = createFileRoute('/_dashboard/placements/groups')({
	component: RouteComponent,
	loader: async ({ context }) => context.queryClient.ensureQueryData(placementGroupsQueryOptions),
});

function RouteComponent() {
	const { updatePlacementGroup, createPlacementGroup, deletePlacementGroup } = usePlacementGroup();
	const { hasPermission } = useAccess();
	const { t } = useTranslation();
	const sheet = useSheet();
	const [placementGroupSelected, setPlacementGroupSelection] = useState<
		PlacementGroupWithPlacements | undefined
	>();
	const { placementsById } = usePlacement();

	const { data: placementGroups } = useQuery(placementGroupsQueryOptions);

	const onRowSelected = useCallback(
		(value: Updater<RowSelectionState>) => {
			const id = Object.entries(
				typeof value === 'function'
					? value(placementGroupSelected ? { [placementGroupSelected.id]: true } : {})
					: value,
			)[0]?.[0];

			const placementGroup = placementGroups.find((row) => row.id === id);

			if (placementGroup) {
				setPlacementGroupSelection(placementGroup);
				sheet.showSheet({
					content: (
						<PlacementGroupForm
							placementGroup={placementGroup}
							onSubmit={onSubmit}
							onRemove={async () => {
								await deletePlacementGroup.mutateAsync({
									param: {
										placementGroupId: placementGroup.id,
									},
								});

								sheet.hideSheet();
							}}
							onCancel={() => {
								sheet.hideSheet();
							}}
						/>
					),
				});
			}
		},
		[placementGroups],
	);

	const onSubmit = useCallback(
		async (form: PlacementGroupFormOutput) => {
			if (form.id) {
				await updatePlacementGroup.mutateAsync({
					json: {
						name: form.name,
						global: form.global,
						description: form.description,
						placementIds: form.placementIds,
					} as PlacementGroupFormOutput,
					param: {
						placementGroupId: form.id,
					},
				});
			} else {
				await createPlacementGroup.mutateAsync({
					json: {
						global: form.global,
						name: form.name,
						description: form.description,
						placementIds: form.placementIds,
					} as PlacementGroupFormOutput,
				});
			}
			setPlacementGroupSelection(undefined);
			sheet.hideSheet();
		},
		[placementGroupSelected],
	);

	return (
		<>
			<div className='flex flex-col gap-6'>
				<div className='flex justify-between'>
					<div className='-ml-2 -mt-2 flex w-2/3 items-baseline'>
						<h1 className='mt-2 ml-2 font-semibold text-base text-foreground leading-6'>
							{t('placement_group.menu')}
						</h1>
						<p className='mt-1 ml-2 truncate text-muted-foreground text-sm'>
							{t('placement_group.description')}
						</p>
					</div>

					<div>
						{hasPermission('placement-create') && (
							<Button
								type='button'
								size={'sm'}
								onClick={() => {
									setPlacementGroupSelection(undefined);
									sheet.showSheet({
										onClose: () => setPlacementGroupSelection(undefined),
										content: (
											<PlacementGroupForm
												onSubmit={onSubmit}
												onCancel={() => {
													sheet.hideSheet();
												}}
											/>
										),
									});
								}}
							>
								<Plus className='mr-2 size-4' />
								{t('placement.create')}
							</Button>
						)}
					</div>
				</div>
				<DataTable
					columns={placementGroupColumns(placementsById)}
					data={placementGroups.filter(({ deletedAt }) => !deletedAt)}
					enableRowSelection={true}
					enableMultiRowSelection={false}
					onRowSelectionChange={onRowSelected}
					rowSelection={placementGroupSelected ? { [placementGroupSelected.id]: true } : {}}
				/>
			</div>
		</>
	);
}
