import { useTranslation } from '@adframe/translation';
import { createFileRoute } from '@tanstack/react-router';

import getAccountClient from '#app/services/internal-api/get-account-client.js';
import GeneralForm from './components/GeneralForm';

export const Route = createFileRoute('/_dashboard/settings/general')({
	component: RouteComponent,
	loader: async ({ context }) => {
		const client = getAccountClient();

		const result = await client.organizations[':organizationId'].$get({
			param: {
				organizationId: context.auth.account!.organization.id,
			},
		});

		return result.json();
	},
});

function RouteComponent() {
	const { t } = useTranslation();

	const organization = Route.useLoaderData();

	return (
		<div>
			<div className='border-border border-b py-3'>
				<h3 className='font-semibold text-foreground text-xl leading-6'>
					{t('settings.general.head')}
				</h3>
			</div>
			<div className='mt-6'>
				<GeneralForm organization={organization} />
			</div>
		</div>
	);
}
