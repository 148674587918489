import { type PropsWithChildren, createContext, useContext, useState } from 'react';

import { useTranslation } from '@adframe/translation';
import {
	Sheet,
	SheetClose,
	SheetContent,
	type SheetContentProps,
	SheetTitle,
} from '@adframe/ui/components/sheet';

type SheetOptions = {
	onClose?: () => void;
	closeButton?: boolean;
	prevent?: boolean;
	title?: string;
	side?: SheetContentProps['side'];
	width?: SheetContentProps['width'];
	overlay?: SheetContentProps['overlay'];
	content: React.ReactNode;
};

type SheetContextType = {
	showSheet: (options: SheetOptions) => void;
	hideSheet: () => void;
};

export const SheetContext = createContext<SheetContextType | undefined>(undefined);

export function SheetProvider<P = unknown>({ children }: PropsWithChildren<P>) {
	const [sheetOptions, setSheetOptions] = useState<SheetOptions | null>(null);
	const { t } = useTranslation();

	const showSheet = (options: SheetOptions) => {
		setSheetOptions(options);
	};

	const hideSheet = () => {
		sheetOptions?.onClose?.();

		setSheetOptions(null);
		setTimeout(() => {
			window.BrevoConversations('show');
		}, 300);
	};

	const onOpenChange = (open: boolean) => {
		if (!open) {
			sheetOptions?.onClose?.();

			hideSheet();
		}
	};

	if (sheetOptions) {
		window.BrevoConversations('hide');
	}

	return (
		<SheetContext.Provider
			value={{
				hideSheet,
				showSheet,
			}}
		>
			<Sheet open={!!sheetOptions} onOpenChange={onOpenChange}>
				{children}
				<SheetContent
					side={sheetOptions?.side ?? 'right'}
					overlay={sheetOptions?.overlay ?? true}
					onInteractOutside={(e) => sheetOptions?.prevent !== false && e.preventDefault()}
					width={sheetOptions?.width ?? 'large'}
				>
					{sheetOptions?.title && <SheetTitle>{sheetOptions?.title}</SheetTitle>}
					{sheetOptions?.closeButton ? <SheetClose /> : null}
					{sheetOptions?.content}
				</SheetContent>
			</Sheet>
		</SheetContext.Provider>
	);
}

export const useSheet = () => {
	const context = useContext(SheetContext);

	if (!context) {
		throw new Error('useSheetContext must be used within an SheetProvider');
	}
	return context;
};
