import { hc } from 'hono/client';

import type { AppType } from '@adframe/worker-adframe';
import { HTTPException } from 'hono/http-exception';
import type { ContentfulStatusCode } from 'hono/utils/http-status';

const overrideFetch: typeof fetch = (url, init) =>
	fetch(url, {
		...init,
		credentials: 'include',
	}).then(async (response) => {
		if (response.ok) {
			return response;
		}

		throw new HTTPException(response.status as ContentfulStatusCode, {
			message: await response.text(),
		});
	});

export default () =>
	hc<AppType>(import.meta.env.PUBLIC_API_ADFRAME_ORIGIN, {
		fetch: overrideFetch,
	});
