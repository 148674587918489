import { createFileRoute, redirect } from '@tanstack/react-router';

import { SidebarProvider } from '@adframe/ui/components/sidebar';
import { Outlet } from '@tanstack/react-router';
import PaymentStatus from '#app/components/PaymentStatus.js';
import { AppSidebar } from '#app/components/sidebar/index.js';
import usePaymentStatus from '#app/hooks/use-payment-status.js';

export const Route = createFileRoute('/_dashboard')({
	component: RouteComponent,
	beforeLoad: ({ context }) => {
		if (context.auth.status === 'anonymous') throw redirect({ to: '/sign' });
	},
});

function RouteComponent() {
	const status = usePaymentStatus();

	const shouldPaiementWarning = ['unpaid', 'canceled', 'past_due'].includes(status);

	return (
		<SidebarProvider>
			<AppSidebar />
			<main className='w-full'>
				{shouldPaiementWarning ? <PaymentStatus status={status} /> : <Outlet />}
			</main>
		</SidebarProvider>
	);
}
