import { createFileRoute, redirect } from '@tanstack/react-router';
import { Outlet } from '@tanstack/react-router';

import { Meteors } from '@adframe/ui/components/meteor';

export const Route = createFileRoute('/sign')({
	component: RouteComponent,
	beforeLoad: ({ context, location }) => {
		if (context.auth.status === 'authenticated' && !location.pathname.endsWith('out')) {
			throw redirect({ to: '/' });
		}
	},
});

function RouteComponent() {
	return (
		<>
			<div className='relative h-full w-full overflow-hidden'>
				<div className='flex min-h-screen flex-1 flex-col items-center justify-center bg-background px-6 py-24 lg:px-8'>
					<div className='z-30 w-1/3 bg-background py-16'>
						<Outlet />
					</div>
				</div>
				<Meteors number={10} />
			</div>
		</>
	);
}
