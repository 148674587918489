import { Button } from '@adframe/ui/components/button';
import { Link } from '@tanstack/react-router';

export default ({ error }: { error: Error }) => (
	<>
		<main className='grid min-h-full place-items-center bg-background px-6 py-24 sm:py-32 lg:px-8'>
			<div className='text-center'>
				<h1 className='mt-4 text-balance font-semibold text-2xl text-foreground tracking-tight sm:text-5xl'>
					Something went wrong
				</h1>
				<p className='mt-6 text-pretty font-medium text-foreground/70 text-lg sm:text-xl/10'>
					Our servers are having issues right now. Please try again later.
				</p>
				{error.message && (
					<p className='mt-3 font-medium text-foreground/70 sm:text-xl/8'>{error.message}</p>
				)}
				<div className='mt-10 flex items-center justify-center gap-x-6'>
					<Button asChild variant={'link'}>
						<Link preload={false} to={'/'}>
							Go back home
						</Link>
					</Button>
				</div>
			</div>
		</main>
	</>
);
