import { useSuspenseQueries } from '@tanstack/react-query';
import { createFileRoute, notFound, redirect } from '@tanstack/react-router';
import { Outlet } from '@tanstack/react-router';

import { getCampaignQueryOptions } from '#app/queries/index.js';

import { HTTPException } from 'hono/http-exception';
import { listCreativesFromCampaignQueryOptions } from '#app/queries/creative.js';
import CreativeRoot from './components/creative/CreativeRoot';
import { CampaignProvider } from './contexts';
import { Route as creativeRoute } from './creatives/$creativeId';

export const Route = createFileRoute('/_dashboard/campaigns/$campaignId')({
	component: RouteComponent,
	staleTime: 0,
	beforeLoad: async ({ params, location, context, preload }) => {
		const campaign = await context.queryClient
			.fetchQuery(getCampaignQueryOptions(params.campaignId as `cmp_${string}`))
			.catch((error) => {
				if (error instanceof HTTPException && error.status === 404) {
					throw notFound();
				}

				throw error;
			});

		const isOnCreativePage = /\/campaigns\/[^/]+\/creatives\/[^/]+/g.test(location.pathname);

		const creative = campaign.creatives
			.filter(({ deletedAt }) => !deletedAt)
			.sort((a, b) => a.createdAt - b.createdAt)
			.at(0);

		if (!isOnCreativePage && creative && !preload) {
			throw redirect({
				to: creativeRoute.to,
				params: {
					campaignId: campaign.id,
					creativeId: creative.id,
				},
			});
		}

		await context.queryClient.ensureQueryData(
			listCreativesFromCampaignQueryOptions(params.campaignId),
		);

		return campaign;
	},
});

function RouteComponent() {
	const params = Route.useParams();

	const [{ data: campaign }, { data: creatives }] = useSuspenseQueries({
		queries: [
			getCampaignQueryOptions(params.campaignId as `cmp_${string}`),
			listCreativesFromCampaignQueryOptions(params.campaignId),
		],
	});

	return (
		<>
			<CampaignProvider value={campaign}>
				<main className='lg:pl-72'>
					<div className='xl:pl-24'>
						<div className='min-h-screen'>
							<div>
								<Outlet />
							</div>
						</div>
					</div>
				</main>

				<aside className='fixed inset-y-0 left-76 hidden w-96 overflow-hidden border-border border-r xl:block'>
					<CreativeRoot campaign={campaign} creatives={creatives} />
				</aside>
			</CampaignProvider>
		</>
	);
}
