import { useTranslation } from '@adframe/translation';
import { createFileRoute } from '@tanstack/react-router';

import { preferenceQueryOptions } from '#app/hooks/use-preference.js';
import PreferenceForm from './components/PreferenceForm';

export const Route = createFileRoute('/_dashboard/settings/preference')({
	component: RouteComponent,
	loader: async ({ context }) => context.queryClient.ensureQueryData(preferenceQueryOptions),
});

function RouteComponent() {
	const { t } = useTranslation();

	const preference = Route.useLoaderData();

	return (
		<div>
			<div className='border-border border-b py-3'>
				<h3 className='font-semibold text-foreground text-xl leading-6'>
					{t('settings.preference.head')}
				</h3>
			</div>
			<div className='mt-6'>
				<PreferenceForm preference={preference} />
			</div>
		</div>
	);
}
