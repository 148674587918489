import type { InferRequestType, InferResponseType } from 'hono';
import { useCallback, useMemo } from 'react';

import type { Account } from '@adframe/database-account/types';
import getSecurityClient from '#app/services/internal-api/get-security-client.js';

import type { UploadRequest } from '@adframe/worker-security';
import { useQueryClient } from '@tanstack/react-query';
import { crush } from 'radash';
import { useTheme } from '#app/providers/theme.tsx';
import i18n from '../i18n';
import { useAccountStore } from '../stores';

const getStatus = (account: Account | null | undefined) => {
	switch (account) {
		case null:
			return 'anonymous' as const;
		case undefined:
			return 'unknown' as const;
		default:
			return 'authenticated' as const;
	}
};

export default function useAuth() {
	const { account, setAccount } = useAccountStore();
	const queryClient = useQueryClient();
	const { setTheme } = useTheme();

	const {
		me,
		sign,
		logout,
		invitations,
		google,
		'sign-up': signUp,
		switch: switchOrg,
	} = getSecurityClient();

	const status = useMemo(() => getStatus(account), [account]);

	const authenticate = useCallback(async () => {
		try {
			const account = await me.$get().then((result): Promise<Account> => result.json());

			setAccount(account);
			i18n.changeLanguage(account.settings.locale);
			setTheme(account.settings.theme ?? 'system');
			return true;
		} catch (error) {
			setAccount(null);
			setTheme('system');
			i18n.changeLanguage(navigator.language);

			return false;
		}
	}, [me, setAccount]);

	const signWithCode = useCallback(
		async (query: InferRequestType<typeof sign.$get>['query']) => {
			await sign.$get({ query });
		},
		[sign],
	);

	const signWithGoogle = useCallback(
		async (json: InferRequestType<typeof google.$post>['json']) => {
			try {
				const account = await google
					.$post({
						json,
					})
					.then((result) => result.json());

				setAccount(account);
				setTheme(account.settings.theme ?? 'system');
			} catch (error) {
				setAccount(null);
				setTheme('system');

				return false;
			}

			return true;
		},
		[google],
	);

	const signConfirmCode = useCallback(
		async (json: InferRequestType<typeof sign.$post>['json']) => {
			const account = await sign.$post({ json }).then((result): Promise<Account> => result.json());

			if (account) {
				setAccount(account);
				i18n.changeLanguage(account.settings.locale);
				setTheme(account.settings.theme ?? 'system');
			}
		},
		[setAccount, sign],
	);

	const signOut = useCallback(async () => {
		try {
			await logout.$post();
		} finally {
			setAccount(null);
		}
	}, [logout, setAccount]);

	const registerInvitation = useCallback(
		async (json: InferRequestType<typeof invitations.$post>['json']) => {
			try {
				const account = await invitations
					.$post({
						json,
					})
					.then((result) => result.json());

				setAccount(account);
			} catch (error) {
				setAccount(null);
			}
		},
		[invitations, setAccount],
	);

	const register = useCallback(
		async (json: InferRequestType<typeof signUp.$post>['json']) => {
			try {
				const account = await signUp
					.$post({ json: { ...json, locale: navigator.language } })
					.then((result) => result.json());
				setAccount(account);
			} catch (error) {
				setAccount(null);
			}
		},
		[signUp],
	);

	/**
	 * Self update user fields
	 */
	const update = useCallback(
		async (json: InferRequestType<typeof me.$put>['json']) =>
			me.$put({ json }).then((result) => result.json()),
		[],
	);

	const upload = async (request: UploadRequest) => {
		const data = new FormData();

		for (const [key, value] of Object.entries(crush(request))) {
			data.append(key, value);
		}

		const account = await fetch(me.$url(), {
			method: 'POST',
			credentials: 'include',
			body: data,
			mode: 'cors',
		}).then((response): Promise<InferResponseType<typeof me.$post>> => response.json());

		setAccount(account);
	};

	const switchOrganization = useCallback(
		async (json: InferRequestType<typeof switchOrg.$post>['json']) => {
			const account = await switchOrg.$post({ json }).then((result) => result.json());

			setAccount(account);

			await Promise.all([
				queryClient.invalidateQueries({ queryKey: ['accessible-campaigns'] }),
				queryClient.invalidateQueries({ queryKey: ['campaigns'] }),
			]);
		},
		[],
	);

	const acceptInvitation = useCallback(async (invitationId: `ivt_${string}`) => {
		const accessibleOrganization = await invitations[':invitationId']
			.$post({ param: { invitationId } })
			.then((result) => result.json());

		if (account) {
			setAccount({
				...account,
				accessibleOrganizations: [...account.accessibleOrganizations, accessibleOrganization],
				pendingInvitations:
					account.pendingInvitations.filter(
						(pendingInvitation) => invitationId !== pendingInvitation.invitationId,
					) ?? [],
			});
		}
	}, []);

	const declineInvitation = useCallback(async (invitationId: `ivt_${string}`) => {
		await invitations[':invitationId']
			.$delete({ param: { invitationId } })
			.then((result) => result.json());

		if (account) {
			setAccount({
				...account,
				pendingInvitations:
					account.pendingInvitations.filter(
						(pendingInvitation) => invitationId !== pendingInvitation.invitationId,
					) ?? [],
			});
		}
	}, []);

	return {
		status,
		account,
		switchOrganization,
		authenticate,
		registerInvitation,
		declineInvitation,
		acceptInvitation,
		update,
		register,
		signWithCode,
		signWithGoogle,
		signConfirmCode,
		signOut,
		upload,
	};
}
