import { useTranslation } from '@adframe/translation';
import { createFileRoute } from '@tanstack/react-router';
import { listInvitationsQueryOptions } from '#app/queries/invitation.ts';
import { listMembersQueryOptions } from '#app/queries/member.js';
import InvitationTable from './components/invitations/InvitationTable';
import UserTable from './components/users/UserTable';

export const Route = createFileRoute('/_dashboard/settings/people')({
	component: RouteComponent,
	loader: async ({ context }) =>
		Promise.all([
			context.queryClient.ensureQueryData(listInvitationsQueryOptions),
			context.queryClient.ensureQueryData(listMembersQueryOptions),
		]),
});

function RouteComponent() {
	const { t } = useTranslation();

	return (
		<>
			<div className='flex flex-col gap-6'>
				<div className='border-border border-b py-3'>
					<h3 className='font-semibold text-foreground text-xl leading-6'>
						{t('settings.people.head')}
					</h3>
				</div>
				<div>
					<InvitationTable />
				</div>
				<div>
					<UserTable />
				</div>
			</div>
		</>
	);
}
