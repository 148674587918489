import { createFileRoute, redirect } from '@tanstack/react-router';
import { listAccessibleCampaignsQueryOptions } from '#app/queries/campaign.js';
import { Route as campaignRoute } from './$campaignId';

export const Route = createFileRoute('/_dashboard/campaigns/')({
	beforeLoad: async ({ context, location }) => {
		const isOnCampaignPage = /\/campaigns\/.+/g.test(location.pathname);

		const { campaigns } = await context.queryClient.fetchQuery(
			listAccessibleCampaignsQueryOptions(),
		);

		const campaign = campaigns.at(0);

		if (!isOnCampaignPage && campaign) {
			throw redirect({
				to: campaignRoute.to,
				from: '/campaigns',
				params: {
					campaignId: campaign.id,
				},
			});
		}
	},
});
