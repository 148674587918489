import { createFileRoute } from '@tanstack/react-router';

import { zodResolver } from '@hookform/resolvers/zod';
import { Turnstile } from '@marsidev/react-turnstile';
import { Link, useNavigate } from '@tanstack/react-router';
import { ChevronLeftCircle, Loader2 } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { toast } from 'sonner';
import { z } from 'zod';

import { useTranslation } from '@adframe/translation';
import { Button } from '@adframe/ui/components/button';
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from '@adframe/ui/components/form';
import { Input } from '@adframe/ui/components/input';
import useAuth from '#app/hooks/use-auth.js';

import { sleep } from 'radash';
import { useState } from 'react';
import logoDark from '#app/assets/adframe-logo-dark-mode.png';
import logoLight from '#app/assets/adframe-logo-light-mode.png';
import { useTheme } from '#app/providers/theme.tsx';

export const Route = createFileRoute('/sign/up')({
	component: RouteComponent,
	staticData: {
		guest: true,
	},
});

const formSchema = z.object({
	email: z.string().email(),
	organizationName: z.string().min(2).max(50).trim(),
	firstName: z.string().min(2).max(30).trim(),
	lastName: z.string().min(2).max(30).trim(),
	turnstile: z.string(),
});

function RouteComponent() {
	const { register } = useAuth();

	const { t } = useTranslation();
	const navigate = useNavigate({ from: '/sign/up' });
	const { theme } = useTheme();
	const [loading, setLoading] = useState(false);

	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			firstName: '',
			lastName: '',
			organizationName: '',
			email: '',
		},
	});

	async function onSubmit(values: z.output<typeof formSchema>) {
		setLoading(true);
		try {
			await register(values);

			await sleep(4000);
			setLoading(false);
			await navigate({ to: '/' });
		} catch (error) {
			toast.error('Something went wrong.', { position: 'top-center' });
			setLoading(false);
		}
	}

	return (
		<>
			<div className='sm:mx-auto sm:w-full sm:max-w-sm'>
				<img
					className='mx-auto h-10 w-auto'
					src={theme === 'light' ? logoLight : logoDark}
					alt='AdFrame Logo'
				/>
				<h2 className='mt-10 text-center font-bold text-2xl text-back leading-9 tracking-tight'>
					{t('sign_up.title')}
				</h2>
			</div>

			<div className='mt-10 sm:mx-auto sm:w-full sm:max-w-sm'>
				<Form {...form}>
					<form onSubmit={form.handleSubmit(onSubmit)} className='space-y-8'>
						<FormField
							control={form.control}
							name='organizationName'
							render={({ field }) => (
								<FormItem>
									<FormLabel>{t('sign_up.company_name')}</FormLabel>
									<FormControl>
										<Input placeholder='Apple' autoComplete='off' {...field} />
									</FormControl>
									<FormMessage />
								</FormItem>
							)}
						/>
						<FormField
							control={form.control}
							name='email'
							render={({ field }) => (
								<FormItem>
									<FormLabel>{t('sign_up.email')}</FormLabel>
									<FormControl>
										<Input placeholder='john@doe.co' autoComplete='off' type='email' {...field} />
									</FormControl>
									<FormMessage />
								</FormItem>
							)}
						/>
						<FormField
							control={form.control}
							name='firstName'
							render={({ field }) => (
								<FormItem>
									<FormLabel>{t('sign_up.first_name')}</FormLabel>
									<FormControl>
										<Input placeholder='John' autoComplete='off' {...field} />
									</FormControl>
									<FormMessage />
								</FormItem>
							)}
						/>
						<FormField
							control={form.control}
							name='lastName'
							render={({ field }) => (
								<FormItem>
									<FormLabel>{t('sign_up.last_name')}</FormLabel>
									<FormControl>
										<Input placeholder='Doe' autoComplete='off' {...field} />
									</FormControl>
									<FormMessage />
								</FormItem>
							)}
						/>
						<FormField
							control={form.control}
							name='turnstile'
							render={({ field }) => (
								<FormItem>
									<Turnstile
										className='m-auto w-full'
										siteKey={import.meta.env.PUBLIC_TURNSTILE_REGISTER_KEY}
										onSuccess={field.onChange}
									/>
									<FormMessage />
								</FormItem>
							)}
						/>
						<div className='flex flex-row-reverse gap-6'>
							<div className='w-3/4'>
								<Button disabled={loading} type='submit' className='w-full'>
									{loading ? (
										<Loader2 className='size-4 animate-spin' />
									) : (
										<span>{t('sign_up.cta')}</span>
									)}
								</Button>
							</div>
							<div className='w-1/4'>
								<Link to='/sign'>
									<Button type='button' variant={'outline'} className='w-full'>
										<ChevronLeftCircle />
									</Button>
								</Link>
							</div>
						</div>
					</form>
				</Form>
			</div>
		</>
	);
}
