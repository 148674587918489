import type React from 'react';
import { type PropsWithChildren, createContext, useContext, useState } from 'react';

import { useTranslation } from '@adframe/translation';
import {
	AlertDialog,
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogDescription,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
} from '@adframe/ui/components/alert-dialog';
import type { Button } from '@adframe/ui/components/button';

type AlertOptions = {
	variant?: React.ComponentPropsWithoutRef<typeof Button>['variant'];
	confirmText?: string;
	cancelText?: string;
	onConfirm?: () => void;
	onCancel?: () => void;
	title: string;
	description?: string | React.ReactNode;
};

type AlertContextType = {
	showAlert: (options: AlertOptions) => void;
	hideAlert: () => void;
};

export const AlertContext = createContext<AlertContextType | undefined>(undefined);

export function AlertProvider<P = unknown>({ children }: PropsWithChildren<P>) {
	const [alertOptions, setAlertOptions] = useState<AlertOptions | null>(null);
	const { t } = useTranslation();

	const onOpenChange = (open: boolean) => {
		if (!open) {
			hideAlert();
		}
	};

	const showAlert = (options: AlertOptions) => {
		setAlertOptions(options);
	};

	const hideAlert = () => {
		setAlertOptions(null);
	};

	const handleConfirm = () => {
		alertOptions?.onConfirm?.();
		hideAlert();
	};

	const handleCancel = () => {
		alertOptions?.onCancel?.();
		hideAlert();
	};

	return (
		<AlertContext.Provider
			value={{
				showAlert,
				hideAlert,
			}}
		>
			{children}
			<AlertDialog open={!!alertOptions} onOpenChange={onOpenChange}>
				<AlertDialogContent>
					<AlertDialogHeader>
						<AlertDialogTitle>{alertOptions?.title}</AlertDialogTitle>
						{alertOptions && (
							<AlertDialogDescription>{alertOptions?.description}</AlertDialogDescription>
						)}
					</AlertDialogHeader>
					<AlertDialogFooter>
						<AlertDialogCancel onClick={handleCancel}>
							{alertOptions?.cancelText ?? t('alert.cancel')}
						</AlertDialogCancel>
						<AlertDialogAction onClick={handleConfirm} variant={alertOptions?.variant ?? 'default'}>
							{alertOptions?.confirmText ?? t('alert.confirm')}
						</AlertDialogAction>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialog>
		</AlertContext.Provider>
	);
}

export const useAlert = () => {
	const context = useContext(AlertContext);
	if (!context) {
		throw new Error('useAlertContext must be used within an AlertProvider');
	}
	return context;
};
