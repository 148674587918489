import { createFileRoute } from '@tanstack/react-router';
import { useNavigate } from '@tanstack/react-router';
import { useEffect } from 'react';

import useAuth from '#app/hooks/use-auth.js';

export const Route = createFileRoute('/sign/out')({
	component: RouteComponent,
});

function RouteComponent() {
	const { signOut } = useAuth();
	const navigate = useNavigate();

	useEffect(() => {
		signOut().then(() => {
			navigate({ to: '/sign' });
		});
	});

	return <></>;
}
